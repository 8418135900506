const dimensions = {
  traject: {
    loadingsorter: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[$(vD_OrderTripDetails.Loading.Volgorde)]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='$(vD_OrderTripDetails.Loading.Volgorde)'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    unloadingsorter: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[$(vD_OrderTripDetails.Unloading.Volgorde)]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='$(vD_OrderTripDetails.Unloading.Volgorde)'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    type: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[$(vD_OrderTripDetails.TrajectType)]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='$(vD_OrderTripDetails.TrajectType)'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    }
  },
};

export {dimensions};

const measures = {
  aantal: {
      trajecten: {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "OrderAantalOrders",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "$(vE_OrderTripAantalOrderTrips)",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 10,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "='$(vL_OrderTripAantalOrderTrips)'",
            "autoSort": true,
            "cId": "UUmPzu",
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
      shipmentsizetotal: {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "Zendinggrootte",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "$(vE_OrderTripShipmentSizeTotal)",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 10,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "='$(vL_OrderTripShipmentSizeTotal)'",
            "autoSort": true,
            "cId": "UUmPzu",
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
  },
  own: {
    omzetperuur: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "OrderTripOmzetPerUur",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripOmzetPerUur)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripOmzetPerUur)'",
          "autoSort": true,
          "cId": "MJZcvk",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_OrderTripOmzetPerUur) < 0.59 * $(vE_OrderTripNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_OrderTripOmzetPerUur) <  $(vE_OrderTripNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
  }
}
;

export {measures};

