const dimensions = {
    other: {
      group: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.Group)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.Group)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    zendinggrootte: {
      klasse: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[Order > Klasse - Grootte]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='Order > Klasse - Grootte'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      klasse_lm: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[Order > Klasse - LM]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='Order > Klasse - LM'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExfpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
    }
};
const measures = {
  own: {
    omzetperuur: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "OrderOmzetPerUur",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderOmzetPerUur)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderOmzetPerUur)'",
          "autoSort": true,
          "cId": "MJZcvk",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_OrderOmzetPerUur) < 0.59 * $(vE_OrderNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_OrderOmzetPerUur) <  $(vE_OrderNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      tripomzetperuur:   {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "TripOmzetPerUur",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_Order_TripOmzetPerUur)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_KPI_Order_TripOmzetPerUur)'",
          "autoSort": true,
          "cId": "NyMgCae",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_Order_TripOmzetPerUur) < 0.59 * $(vE_OrderNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_Order_TripOmzetPerUur) <  $(vE_OrderNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
},
};

export {measures, dimensions};