import React from 'react';

import orderList from '../../margemonitor/components/orders/dimensions-FRSD';
import ritList from '../../margemonitor/components/ritten/dimensions-FRSD';
import orderTripList from '../../margemonitor/components/trajecten/dimensions-FRSD';

const KM_aansluiting = React.lazy(() => import('./../../views/Maatwerk/WIJ/KM-aansluiting'));
const Uren_aansluiting = React.lazy(() => import('./../../views/Maatwerk/WIJ/ControleUren'));
const NarekenenKPI = React.lazy(() => import('./../../views/Maatwerk/FRV/NarekenenKPI'));
const Operationeelrapport = React.lazy(() => import('../../views/Maatwerk/FRS/operationeelrapport/operationeelrapport'));
const Klantrapport = React.lazy(() => import('../../views/Maatwerk/FRV/klantrapport/klantrapport'));


// const options = {
//   dimensions: {
//     order: orderList,
//     rit: ritList,
//     traject: orderTripList,
//   },
  // config: {
  //   host: 'qliksense.fritom.nl',
  //   secure: true,
  //   port: 443,
  //   prefix: '',
  //   appId: 'df4661c9-6d06-411e-8161-08e4ee14f4df',    // Production
  //   //appId:'e1603f1b-654c-4927-8fdd-674304103039',     //Dev
  // },
  // connections: {
  //   qlikCloud: false, 
  //   vizApi: true,
  //   engineApi: true,
  //   useUniqueSessionID: 'MargeMonitor'
  // },

// const options = {
//   dimensions: {
//     order: orderList,
//     rit: ritList,
//     traject: orderTripList,
//   },
//   config: {
//     host: 'qliksense.climbertoolbox.nl',
//     secure: true,
//     port: 443,
//     prefix: '',
//     appId: 'cbf32a1d-5ef9-44bc-a30a-e7760ddd3bbb',    // Production
//     //appId:'e1603f1b-654c-4927-8fdd-674304103039',     //Dev
//   },
//   connections: {
//     qlikCloud: false, 
//     vizApi: true,
//     engineApi: true,
//     useUniqueSessionID: 'MargeMonitor'
//   },

const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  config: {
    host: 'transportcostconsult.eu.qlikcloud.com',
    secure: true,
    port: '443',
    prefix: '',
    webIntegrationId: 'm3XR4ybGHIQ5ZjpgxYZbQZtcAtFOCt94',  
    appId: "ec5d7aa9-0ff2-42b7-8d61-053ae3bc0a0e"
  },
  connections: {
    qlikCloud: true, 
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },

  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Year)'],
            placeholder: 'Jaar',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Month)'],
            placeholder: 'Maand',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
            expanded: true, 
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Quarter)'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Week)'],
            placeholder: 'Week',
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_WeekDay)]'],
            placeholder: 'Dag',
          }
        },   
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Date)'],
            placeholder: 'Datum',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.District)]'],
            placeholder: 'Gemeente',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Province)]'],
            placeholder: 'Provincie',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Land)]'],
            placeholder: 'Land (ISO)',
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.District)]'],
            placeholder: 'Gemeente',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Province)]'],
            placeholder: 'Provincie',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Land)]'],
            placeholder: 'Land (ISO)',
          },
        },
      ],
    },
    order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Zending',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Dossier)]'],
            placeholder: 'Dossier',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Kenmerken'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.PlanGroup)]'],
            placeholder: 'Plangroep',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Reference)]'],
            placeholder: 'Referentie',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Status)]'],
            placeholder: 'Status',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Service)]'],
            placeholder: 'Service',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter', 
          props: {
            cols: ['[$(vD_Order.Type)]'], 
            placeholder: 'Service omschrijving', 
            showStateInDropdown: true, 
            autoSortByState: 1
          }
        },
        {
          type: 'QdtFilter', 
          props: {
            cols: ['[$(vD_Order.Outsourced)]'], 
            placeholder: 'Order uitbesteed', 
            showStateInDropdown: true, 
            autoSortByState: 1
          }
        },
        {
          type: 'title',
          title: 'Opdrachtgever'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Order.Customer.Name)'],
            placeholder: 'Naam',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Customer.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Customer.Type)]'],
            placeholder: 'Type',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: 'Debiteur'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'Number',
            showStateInDropdown: true,
          },
        }
      ],
    },
    trip: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Kenmerken" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.ResourceCombination)]'],
            placeholder: 'Resource Combination',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.PlanGroupName)]'],
            placeholder: 'Plangroep',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Auto nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Auto naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Chauffeur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Chauffeur naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Kind.Description)]'],
            placeholder: 'Soort',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.SearchCode)]'],
            placeholder: 'Zoekcode',
            showStateInDropdown: true,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
      {
        title: true,
        name: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        title: true,
        name: 'Maatwerk',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        name: 'KM aansluiting',
        url: '/frv/km-aansluiting',
        icon: 'fa fa-check',
      },
      {
        name: 'Uren aansluiting',
        url: '/frv/uren-aansluiting',
        icon: 'fa fa-clock',
      },
      {
        name: 'Narekenen KPI',
        url: '/frv/narekenen-kpi',
        icon: 'fa fa-calculator'
      },
      {
        name: 'Operationeel rapport',
        url: '/frv/operationeelrapport',
        icon: 'fa fa-calculator',
      },
      {
        name: 'Klantrapport',
        url: '/frv/klantrapport',
        icon: 'fa fa-calculator',
      },
    ]
  },
  routes: [
    { path: '/frv/km-aansluiting', name: 'FRV / KM aansluiting', component: KM_aansluiting },
    { path: '/frv/uren-aansluiting', name: 'FRV / Uren aansluiting', component: Uren_aansluiting },
    { path: '/frv/narekenen-kpi', name: 'FRV / Narekenen KPI', component: NarekenenKPI },
    { path: '/frv/operationeelrapport', name: 'FRV / Operationeelrapport', component: Operationeelrapport },
    { path: '/frv/klantrapport', name: 'FRV / Klantrapport', component: Klantrapport },

  ],
  fieldSettings:{
    period: 'vD_Month'
  },
  selectionBar: 'Nebula',
  rollingPeriod: false,
  defaultSelections: [
    {
      field: 'Jaar',
      expression: '=year(today())'
    }
  ]
};

export default options;
